<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasTradeStatusType="true"
      :hasStore="true"
      :hasTerminal="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "detail"],
      reportType: "simple",
    };
  },
  components: {
    conditionPart,
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        return [
          { name: "InvoiceNumber" },
          { name: "SalNumber" },
          { name: "CustomerName" },
          { name: "InvoiceTime" },
          { name: "UniformNumber" },
          { name: "Total" },
          { name: "YTaxPrice" },
          { name: "TaxPrice" },
          { name: "YTaxPriceIncludeTax" },
          { name: "NTaxPrice" },
          { name: "ZTaxPrice" },
          { name: "Memo" },
          { name: "VehicleNumber" },
          { name: "OriginalInvoice" },
          { name: "SalMemo" },
        ];
      } else {
        return [
          { name: "BusinessDay" },
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "InvoiceBegin" },
          { name: "InvoiceEnd" },
          { name: "Total" },
          { name: "YTaxPrice" },
          { name: "TaxPrice" },
          { name: "YTaxPriceIncludeTax" },
          { name: "NTaxPrice" },
          { name: "ZTaxPrice" },
          { name: "Sheets" },
          { name: "InvoiceVoid" },
          { name: "VoidSheets" },
        ];
      }
    },
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getInvoice(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getInvoice(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printInvoice(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;

      const vm = this;
      this.$api.reports.getInvoice(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
