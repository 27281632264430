const tw = {
  //language
  langOption: {
    lang_title: "語言",
    lang_tw: "中文",
    lang_en: "英文",
  },
  //language

  //navbar
  navBasic: {
    title: "基本資料",
    stores: "門市",
    terminals: "收銀台",
    departments: "部門",
    employees: "員工",
    foodmajorkinds: "商品大類別",
    foodkinds: "商品類別",
    foods: "商品",
    tastekinds: "口味",
    combokinds: "套餐",
    functions: "功能鍵",
    pays: "付款別",
    directorykinds: "科目",
    accounts: "帳戶",
    pricelabels: "價格標",
    brands: "品牌",
    warehouses: "倉庫",
    suppliers: "廠商",
  },
  navModules: {
    title: "模組設定",
    authority: "權限",
    function: "功能鍵",
    invisible: "商品隱藏",
    sequence: "商品順序",
    promotion: "促銷",
    day: "時間",
    stores: "門市",
  },
  navReport: {
    title: "報表",
    saletitle: "銷售",
    sale: "銷售報表",
    classsale: "班別報表",
    invoice: "發票報表",
    invoiceprize: "中獎發票報表",
    employeeanalysis: "員工報表",
    workrecord: "員工出勤表",
    saler: "業務員報表",
    people: "人數報表",
    coupon: "折價券報表",
    saleorder: "訂單報表",
    collectionpayment: "代收代支表",
    orderfoodstatistic: "訂單商品統計表",
    foodmajorkindstatistic: "大類統計表",
    foodkindstatistic: "類別統計表",
    foodsalestatistic: "商品銷售統計表",
    fooddaystatistic: "商品日統計表",
    foodsale: "商品銷售表",
    free: "招待報表",
    customerfree: "會員招待報表",
    timestatistic: "時段統計表",
    tastetatistic: "口味統計表",
    pay: "付款類別報表",
    salepay: "付款別報表",
    erptitle: "進銷存",
    supplier: "廠商交易報表",
    supplierfoodsale: "廠商商品銷售表",
    supplierfoodsaleoriginal: "廠商商品銷售表(原價)",
    supplierpurchaseorder: "廠商採購明細表",
    foodpurchaseorder: "商品採購表",
    foodpurchase: "商品進貨表",
    foodpurchasedetail: "商品進貨明細表",
    purchaseorder: "採購單報表",
    purchase: "進貨單報表",
    purchasereturn: "進退單報表",
    stockadjust: "調整單報表",
    stockadjustdirectory: "調整科目統計表",
    stocktransfers: "調撥單報表",
    inventory: "盤點單報表",
    stock: "庫存現量表",
    safeqty: "低於安全存量表",
    inventorypandl: "盤點盈虧表",
    foodmovement: "商品異動表",
    combinationtrade: "組合單報表",
    seperationtrade: "拆解單報表",
    customertitle: "會員",
    customer: "會員交易報表",
    customerfoodsale: "會員商品銷售表",
    customersalestatistic: "會員銷售統計表",
    deposit: "儲值交易報表",
    integral: "積點交易報表",
    customerstock: "會員庫存表",
    customerstockmovement: "會員庫存異動表",
    pgtalkdeposit: "PGTalk儲值交易表",
    saleprofit: "銷售利潤表",
    creditcard: "信用卡交易表",
    stockbatch: "批號庫存現量表",
    stockbatchmovement: "批號庫存異動表",
    erpsummary: "商品進銷存彙總表",
  },
  navCustomer: {
    title: "會員管理",
    customers: "會員",
    membersetting: "會員設定",
    memberupgraderules: "會員升等規則",
    integralrules: "積分規則",
    ocardrules: "Ocard 促銷規則",
    memberweb: "會員網站",
  },
  navERP: {
    titleLYERP: "凌越進銷存",
    titleDWERP: "鼎新進銷存",
    titleERP: "進銷存",
    titleInvoice: "單據",
    sals: "銷貨單",
    salreturns: "銷貨退出單",
    purchaseorders: "採購單",
    purchases: "進貨單",
    purchasereturns: "進貨退出單",
    stockadjusts: "調整單",
    stocktransfers: "調撥單",
    inventorys: "盤點單",
    inventorymachine: "盤點機",
    safeqty: "安全存量",
    lystock: "庫存",
    dwstock: "庫存",
  },
  navCombination: {
    title: "組合品",
    combinations: "組合品",
    combinationtrades: "組合單",
    seperationtrades: "拆解單",
  },
  navAccount: {
    title: "帳款",
    receivables: "應收帳款",
    receipts: "收款單",
    payables: "應付帳款",
    payments: "付款單",
  },
  navOnlineOrder: {
    title: "線上訂單管理",
    onlineorderstore: "店家設定",
    onlineorderwork: "營業時間",
    onlineorderfood: "商品設定",
    ubereats: "UberEats",
  },
  navDownload: {
    title: "下傳",
    downloads: "資料下傳",
  },
  //navbar

  //adminMenu
  adminMenu: {
    company: "公司",
    user: "使用者",
    password: "修改密碼",
    logout: "登出",
  },
  //adminMenu

  //viewName
  viewName: {
    home: "首頁",
    stores: "門市",
    businesses: "營業人",
    utaiforms: "友泰申請單",
    ushowforms: "百揚需求信",
    invoiceprizes: "中獎名單",
    devices: "終端機",
    users: "使用者",
    terminals: "收銀台",
    employees: "員工",
    customers: "會員",
    membersetting: "會員設定",
    memberupgraderules: "會員升等規則",
    integralrules: "積分規則",
    ocardrules: "Ocard 促銷規則",
    foodmajorkinds: "商品大類別",
    foodkinds: "商品類別",
    foods: "商品",
    departments: "部門",
    tastekinds: "口味",
    foodkindtastes: "商品類別口味",
    foodtastes: "商品口味",
    combokinds: "套餐",
    foodcombos: "商品套餐",
    functions: "功能鍵資料",
    comboadd: "新增套餐",
    downloads: "資料下傳",
    downloadadd: "新增下傳",
    moduleauthority: "權限模組",
    modulefoodkind: "商品類別模組",
    modulefood: "商品模組",
    modulestore: "門市模組",
    modulestoreselect: "門市",
    dirmoduledevice: "收銀台模組",
    moduleterminal: "收銀台模組",
    moduleemployee: "員工模組",
    moduleinvisible: "商品隱藏模組",
    modulesequence: "商品順序模組",
    modulefunction: "功能鍵模組",
    authority: "權限",
    foodkindsequence: "商品類別順序",
    foodsequence: "商品順序",
    functionbutton: "功能鍵",
    functionbuttonadd: "新增功能鍵",
    reportsale: "銷售報表",
    reportsaldata: "銷售單",
    reportinvoice: "發票報表",
    reportinvoiceprize: "中獎發票報表",
    reportcustomer: "會員報表",
    customersimple: "會員交易簡要表",
    customerdetail: "會員交易明細表",
    reportsupplier: "廠商報表",
    suppliersimple: "廠商交易簡要表",
    supplierdetail: "廠商交易明細表",
    reportsupplierfoodsale: "廠商商品銷售表",
    reportsupplierfoodsaleoriginal: "廠商商品銷售表(原價)",
    supplierfoodsale: "廠商商品銷售統計表",
    invoicedetail: "發票明細表",
    invoicesimple: "發票簡要表",
    reportworkrecord: "員工出勤表",
    workrecorddetail: "員工出勤明細表",
    workrecordsimple: "員工出勤簡要表",
    saledetail: "銷售明細表",
    salesimple: "銷售簡要表",
    saleorderdetail: "訂單明細表",
    saleordersimple: "訂單簡要表",
    salesummary: "銷售彙總表",
    foodsalestatistic: "商品銷售統計表",
    fooddaystatistic: "商品日統計表",
    foodsaledetail: "商品銷售明細表",
    reportfoodsale: "商品銷售表",
    reportcollectionpayment: "代收代支表",
    collectionpaymentdetail: "代收代支明細表",
    foodkindstatistic: "類別統計表",
    foodmajorkindstatistic: "大類統計表",
    reportfree: "招待報表",
    freestatistic: "招待統計表",
    freedetail: "招待明細表",
    reportcustomerfree: "會員招待統計表",
    customerfreestatistic: "會員招待統計表",
    timestatistic: "時段統計表",
    employeeanalysis: "員工分析表",
    modulepromotion: "促銷模組",
    directorykinds: "科目",
    pays: "付款別",
    promotion: "促銷設定",
    sales: "銷貨單",
    salereturns: "銷貨退出單",
    inventorys: "盤點單",
    inventoryadd: "新增盤點單",
    inventoryedit: "編輯盤點單",
    stockadjusts: "調整單",
    stockadjustadd: "新增調整單",
    stockadjustedit: "編輯調整單",
    stocktransfers: "調撥單",
    stocktransferadd: "新增調撥單",
    stocktransferedit: "編輯調撥單",
    stocktransfercheck: "點收調撥單",
    sals: "銷貨單",
    saladd: "新增銷貨單",
    saledit: "編輯銷貨單",
    salreturns: "銷貨退出單",
    salreturnadd: "新增銷退單",
    salreturnedit: "編輯銷退單",
    purchases: "進貨單",
    purchaseorderturn: "新增進貨單",
    purchaseadd: "新增進貨單",
    purchaseedit: "編輯進貨單",
    purchasereturns: "進貨退出單",
    purchasereturnadd: "新增進退單",
    purchasereturnedit: "編輯進退單",
    purchaseorders: "採購單",
    purchaseorderadd: "新增採購單",
    purchaseorderedit: "編輯採購單",
    payments: "付款單",
    paymentadd: "新增付款單",
    paymentedit: "編輯付款單",
    receivables: "應收帳款",
    receipts: "收款單",
    receiptadd: "新增收款單",
    receiptedit: "編輯收款單",
    stock: "庫存現量表",
    payables: "應付帳款",
    reportfoodmovement: "商品異動表",
    foodmovementsimple: "商品異動簡要表",
    foodmovementdetail: "商品異動明細表",
    paymentsimple: "付款單簡要表",
    paymentdetail: "付款單明細表",
    payablesimple: "應付帳款簡要表",
    payabledetail: "應付帳款明細表",
    receiptsimple: "收款單簡要表",
    receiptdetail: "收款單明細表",
    receivablestatistic: "應收帳款統計表",
    receivablesimple: "應收帳款簡要表",
    receivabledetail: "應收帳款明細表",
    paydetail: "付款類別明細表",
    paysimple: "付款類別簡要表",
    reportpay: "付款類別報表",
    salepaydetail: "付款別明細表",
    salepaysimple: "付款別簡要表",
    reportsalepay: "付款別報表",
    dirday: "時間模組",
    daymodule: "時間模組",
    day: "時間",
    warehouses: "倉庫",
    suppliers: "廠商",
    tastestatistic: "口味統計表",
    pricelabels: "價格標",
    brands: "品牌",
    safeqty: "安全存量",
    reportdeposit: "儲值交易報表",
    depositsaledetail: "儲值消費明細表",
    depositstoredetail: "儲值加值明細表",
    reportsafeqty: "低於安全庫存量表",
    supplierfoods: "廠商商品",
    pyreceivables: "應收帳款",
    reportinventorypandl: "盤點盈虧表",
    inventorypandldetail: "盤點盈虧明細表",
    reportclasssale: "班別銷貨報表",
    classsalesimple: "班別銷貨簡要表",
    classsalestatistic: "班別銷貨統計表",
    salesimplesaler: "銷貨簡要表(業務員)",
    saledetailsaler: "銷貨明細表(業務員)",
    daystatisticsaler: "日統計表(業務員)",
    reportsaler: "業務員報表",
    reportpurchase: "進貨單報表",
    reportpurchasereturn: "進退單報表",
    reportpurchaseorder: "採購單報表",
    purchasesimple: "進貨簡要表",
    purchasedetail: "進貨明細表",
    purchasereturnsimple: "進退簡要表",
    purchasereturndetail: "進退明細表",
    purchaseordersimple: "採購簡要表",
    purchaseorderdetail: "採購明細表",
    reportsupplierpurchaseorder: "廠商採購明細表",
    supplierpurchaseorderdetail: "廠商採購明細表",
    reportfoodpurchaseorder: "商品採購表",
    foodpurchaseorder: "商品採購表",
    foodpurchaseorderdetail: "商品採購明細表",
    reportfoodpurchase: "商品進貨表",
    foodpurchaseorder: "商品採購表",
    foodpurchase: "商品進貨表",
    foodpurchasedetail: "商品進貨明細表",
    reportstockadjust: "調整單報表",
    stockadjustsimple: "調整單簡要表",
    stockadjustdetail: "調整單明細表",
    reportstockadjustdirectory: "調整單科目統計表",
    reportstocktransfers: "調撥單報表",
    stocktransfersimple: "調撥單簡要表",
    stocktransferdetail: "調撥單明細表",
    peopledetail: "人數明細表",
    peoplesimple: "人數簡要表",
    peoplestatistic: "人數統計表",
    reportpeople: "人數報表",
    reportintegral: "積點交易報表",
    integraltradedetail: "積點交易明細表",
    integraltradestatistic: "積點交易統計表",
    lystock: "凌越庫存",
    dwstock: "鼎新庫存",
    reportsaleorder: "訂單報表",
    reportorderfoodstatistic: "訂單商品統計表",
    inventorymachine: "盤點機",
    reportinventory: "盤點單報表",
    inventorysimple: "盤點單簡要表",
    inventorydetail: "盤點單明細表",
    accounts: "帳戶",
    coupondetail: "折價券明細表",
    reportcoupon: "折價券報表",
    onlineorderstore: "店家設定",
    onlineorderwork: "營業時間",
    moduleonlineorderfood: "商品設定",
    ubereats: "UberEats",
    lineordersetting: "LINE點餐設定",
    combinations: "組合品",
    combinationedit: "編輯組合品",
    combinationtrades: "組合單",
    combinationtradeadd: "新增組合單",
    combinationtradeedit: "編輯組合單",
    seperationtrades: "拆解單",
    seperationtradeadd: "新增拆解單",
    combinationtradereturn: "新增拆解單",
    seperationtradeedit: "編輯拆解單",
    reportcombinationtrade: "組合單報表",
    reportseperationtrade: "拆解單報表",
    reportcustomerstock: "會員庫存表",
    reportcustomerstockmovement: "會員庫存異動表",
    reportcustomerfoodsale: "會員商品銷售表",
    reportcustomersalestatistic: "會員銷售統計表",
    reportpgtalkdeposit: "PGTalk儲值交易表",
    pgtalkdepositdetail: "PGTalk儲值交易明細表",
    customerstock: "會員庫存表",
    customerstockmovementdetail: "會員庫存異動表",
    reportsaleprofit: "銷售利潤表",
    reportcreditcard: "信用卡交易表",
    reportstockbatch: "批號庫存現量表",
    reportstockbatchmovement: "批號庫存異動表",
    reporterpsummary: "商品進銷存彙總表",
    changeprice: "變更售價別",
    memberweb: "會員網站",
    specifications: "商品款式",
    errormessage: "錯誤訊息",
    thirdparty: "第三方",
    system: "系統",
    notification: "推播",
    webhook: "回呼",
    tablemaps: "桌位",
    foodbrands: "商品品牌",
    integralfoodkind: "商品類別",
    integralfood: "商品",
  },
  //viewName

  //columnName
  columnName: {
    name: "名稱",
    storenumber: "門市編號",
    storename: "門市名稱",
    terminalnumber: "收銀台編號",
    terminalname: "收銀台名稱",
    foodkindnumber: "類別編號",
    foodkindname: "類別名稱",
    onlineordercategoryname: "類別名稱",
    foodmajorkindnumber: "大類別編號",
    foodmajorkindname: "大類別名稱",
    barcode: "條碼",
    barcode2: "條碼2",
    barcode3: "條碼3",
    departmentnumber: "部門編號",
    departmentname: "部門名稱",
    foodnumber: "商品編號",
    onlineorderitemname: "商品名稱",
    foodname: "商品名稱",
    foodname2: "商品名稱2",
    foodmemo: "備註",
    qty: "數量",
    safeqty: "安全存量",
    lackqty: "不足量",
    pickedqty: "已取量",
    notpickedqty: "未取量",
    salprice: "單價",
    salprice1: "售價1",
    salprice2: "售價2",
    salprice3: "售價3",
    salprice4: "售價4",
    salprice5: "售價5",
    salprice6: "售價6",
    cost: "成本",
    totalcost: "總成本",
    avgcost: "平均成本",
    totalavgcost: "總成本(平均)",
    differencetotalcost: "盈虧總成本",
    differencetotal: "盈虧總價",
    grossprofit: "毛利",
    profit: "毛利",
    grossmargin: "毛利率",
    foodsaleproportion: "商品銷售比",
    saleproportion: "銷售比",
    total: "小計",
    saltotal: "單據總計",
    alltotal: "單據總計",
    saletotal: "銷售額",
    salereturntotal: "銷退額",
    totaldiscountprice: "折扣金額",
    discountprice: "折扣金額",
    totalfreeprice: "折讓金額",
    freeprice: "折讓金額",
    unit: "單位",
    employeenumber: "員工編號",
    employeename: "員工名稱",
    salernumber: "業務員編號",
    salername: "業務員名稱",
    warehousenumber: "倉庫編號",
    warehousename: "倉庫名稱",
    fromwarehousenumber: "調出倉庫編號",
    fromwarehousename: "調出倉庫名稱",
    towarehousenumber: "調入倉庫編號",
    towarehousename: "調入倉庫名稱",
    suppliernumber: "廠商編號",
    suppliername: "廠商名稱",
    purchasetotal: "進貨金額",
    purchasereturntotal: "退貨金額",
    salefoodname: "自訂商品",
    customernumber: "會員編號",
    customername: "會員名稱",
    email: "電子信箱",
    telephone: "電話",
    cellphone: "手機",
    memo: "備註",
    tradememo: "單據備註",
    salmemo: "單據備註",
    foodmemo: "明細備註",
    gender: "性別",
    username: "名稱",
    roletype: "角色",
    businessid: "營業人編號",
    businessname: "營業人名稱",
    combokindname: "套餐類別名稱",
    comboname: "套餐名稱",
    tastekindname: "口味類別名稱",
    onlineordermodifiergroupname: "口味類別名稱",
    tastename: "口味名稱",
    onlineordermodifiername: "口味名稱",
    price: "金額",
    invoicedate: "發票日期",
    invoicenumber: "發票號碼",
    period: "期別",
    postalcode: "郵遞區號",
    address: "地址",
    phone: "電話",
    invoiceemail: "寄送Email",
    invoiceprizetype: "中獎獎別",
    invoiceprizeamount: "中獎金額",
    invoiceprizenumber: "中獎號碼",
    businessnumber: "統一編號",
    purchasenumber: "單號",
    salnumber: "單號",
    tradenumber: "單號",
    depositrecordnumber: "儲值單號",
    purchasedate: "日期",
    businessday: "日期",
    businessclass: "班別",
    stockadjustnumber: "調整單號",
    stockadjustdate: "調整日期",
    fromstockadjustnumber: "調出調整單號",
    tostockadjustnumber: "調入調整單號",
    stocktransfernumber: "調撥單號",
    stocktransferdate: "調撥日期",
    checkqty: "點收數量",
    checkername: "點收人",
    salername: "業務員",
    buildername: "建立者",
    paytype: "付款別",
    payname: "付款名稱",
    functionname: "功能名稱",
    value: "值",
    directorytype: "科目種類",
    directorytypetext: "科目種類",
    directorykindname: "科目類別名稱",
    directoryname: "科目名稱",
    moduletype: "模組種類",
    modulename: "模組名稱",
    promotionname: "群組名稱",
    promotiondetname: "小群組名稱",
    buyernumber: "採購員編號",
    buyername: "採購員",
    receiptnumber: "收款單號",
    receiptdate: "收款日期",
    paymentnumber: "付款單號",
    paymentdate: "付款日期",
    tradetype: "交易別",
    tradetypetext: "交易別",
    eattype: "用餐別",
    eattypetext: "用餐別",
    payable: "原應付金額",
    payed: "已付金額",
    nopay: "未付金額",
    accountdate: "結帳日",
    receivable: "應收金額",
    received: "已收金額",
    downloadname: "名稱",
    counts: "次數",
    salecounts: "交易次數",
    voidcounts: "作廢次數",
    foodcounts: "商品數",
    foodaveragesalprice: "商品平均單價",
    totalperreceipt: "每單平均消費",
    foodcountsperreceipt: "每單平均商品數",
    peopleperreceipt: "每單平均人數",
    totalperperson: "客單價",
    foodcountsperperson: "每人平均商品數",
    people: "人數",
    time: "時段",
    times: "次數",
    invoicebegin: "起",
    invoiceend: "訖",
    ytaxprice: "應稅額",
    taxprice: "稅額",
    ytaxpriceincludetax: "含稅額",
    ztaxprice: "零稅額",
    ntaxprice: "免稅額",
    netprice: "淨額",
    difference: "盈虧",
    sheets: "張數",
    invoicevoid: "作廢",
    voidsheets: "作廢張數",
    saltime: "銷售時間",
    invoicetime: "銷售時間",
    deliverytime: "出貨時間",
    tradetime: "交易時間",
    createtime: "建立時間",
    updatetime: "最後修改時間",
    cash: "現金",
    change: "找零",
    uniformnumber: "統一編號",
    inventorydate: "盤點日期",
    inventorynumber: "盤點單號",
    inventoryqty: "盤點數量",
    stockqty: "庫存數量",
    initialqty: "期初庫存量",
    lastqty: "期末庫存量",
    salqty: "銷售數量",
    voidsalqty: "銷退數量",
    purchaseqty: "進貨數量",
    purchaseprice: "進貨價格",
    purchasereturnqty: "進退數量",
    adjustqty: "調整數量",
    combinationinqty: "組合入庫",
    combinationoutqty: "領料扣庫",
    seperationinqty: "拆解扣庫",
    seperationoutqty: "退料入庫",
    transferinqty: "撥入",
    transferoutqty: "撥出",
    tradedate: "交易日期",
    deviceid: "收銀台編號",
    devicename: "收銀台名稱",
    cpuid: "金鑰",
    statustype: "狀態",
    statustypetext: "狀態",
    principlenumber: "負責人編號",
    principlename: "負責人名稱",
    daybegin: "開始日期",
    dayend: "結束日期",
    timebegin: "開始時間",
    timeend: "結束時間",
    sunday: "日",
    monday: "一",
    tuesday: "二",
    wednesday: "三",
    thursday: "四",
    friday: "五",
    saturday: "六",
    daymodulename: "時間模組",
    integral: "積點",
    integraladd: "獲得積點",
    integralminus: "折抵積點",
    birth: "生日",
    gender: "性別",
    degree: "等級",
    customerleveltypetext: "會員分級",
    days: "累積天數",
    memberupgraderule: "等級",
    memberupgraderuletext: "等級",
    creditcardprice: "信用卡金額",
    creditcardnumber: "信用卡號",
    paytype1: "結帳別1",
    paytype2: "結帳別2",
    linepayprice: "Line Pay",
    scan2payprice: "Scan2Pay",
    couponprice: "折價券",
    deposit: "儲值金",
    depositminus: "使用儲值金",
    lastrechargeamount: "剩餘可儲值金額",
    tradenumber: "單號",
    executetime: "交易時間",
    easycardprice: "悠遊卡",
    number: "號碼",
    overcharge: "溢收",
    originalprice: "單價",
    originaltotal: "原總價",
    originalqty: "原數量",
    identifier: "識別碼",
    totallength: "總長度",
    pricelabeltype: "種類",
    foodposition: "商品位置",
    foodlength: "商品長度",
    priceposition: "價格位置",
    pricelength: "價格長度",
    pricedecimalnumber: "價格小數位",
    qtyposition: "數量位置",
    qtylength: "數量長度",
    qtydecimalnumber: "數量小數位",
    discount: "折扣",
    towork: "上班時間",
    offwork: "下班時間",
    worktime: "工時",
    worktimehour: "工時(時)",
    worktimeminute: "工時(分)",
    devicecounts: "台數",
    principle: "負責人",
    contactperson: "聯絡人",
    faxnumber: "傳真號碼",
    startdate: "開始日期",
    enddate: "結束日期",
    months: "月",
    monthlyfee: "月租費",
    freedeposit: "贈送儲值金",
    beforedeposit: "儲值金(前)",
    afterdeposit: "儲值金(後)",
    beforestock: "庫存量(前)",
    peopletype1: "客群1",
    peopletype2: "客群2",
    peopletype3: "客群3",
    peopletype4: "客群4",
    totalpeople: "總人數",
    lywarehousenumber: "凌越倉庫編號",
    lyuniformnumber: "凌越申報統編",
    vehiclenumber: "載具號碼",
    originalinvoice: "原發票號碼",
    modulestorename: "門市模組名稱",
    isfinalpaymentpayed: "已付尾款",
    finalpayment: "尾款",
    finalpaymentdate: "完成時間",
    advanceddeposit: "訂金",
    producttype: "產品名稱",
    producttypetext: "產品名稱",
    accountnumber: "帳戶編號",
    accountname: "帳戶名稱",
    couponname: "折價券名稱",
    expiredate: "到期日",
    sourcesalnumber: "來源單號",
    sourcesaltime: "來源時間",
    sourceterminalnumber: "來源收銀台編號",
    sourceterminalname: "來源收銀台",
    discountsalnumber: "使用單號",
    discountsaltime: "使用時間",
    discountterminalnumber: "使用收銀台編號",
    discountterminalname: "使用收銀台",
    onlineorderstorename: "商店名稱",
    onlineorderphone: "商店電話",
    onlineorderaddress: "商店地址",
    collectionpaymenttype: "種類",
    onlineordermenuname: "菜單名稱",
    minqty: "最少",
    maxqty: "最多",
    taxtype: "稅別",
    taxtypetext: "稅別",
    starttime: "開始時間",
    endtime: "結束時間",
    combinationtradedate: "組合日期",
    combinationtradenumber: "組合單號",
    seperationtradedate: "拆解日期",
    seperationtradenumber: "拆解單號",
    combinationwarehousenumber: "主件倉庫編號",
    combinationwarehousename: "主件倉庫名稱",
    combinationfoodnumber: "主件品號",
    combinationfoodname: "主件品名",
    combinationqty: "主件數量",
    combinationtotal: "主件總計",
    combinationunit: "主件單位",
    stockadjusttype: "調整類別",
    stockadjusttypetext: "調整類別",
    promotiontype: "促銷種類",
    promotiontypetext: "促銷種類",
    balance: "沖帳金額",
    ocardruleid: "ID",
    ocardrulename: "名稱",
    ocardpromotiontype: "促銷規則",
    ocardpromotiontypetext: "促銷規則",
    ocardrulefoodnametext: "兌換商品",
    promotionvalue: "值",
    salesperson: "業務員",
    partner: "購買公司",
    utailocationtype: "公司所在地",
    utailocationtypetext: "公司所在地",
    applytype: "申請模式",
    applytypetext: "申請模式",
    termleasetype: "使用期間",
    termleasetypetext: "使用期間",
    companyname: "公司名稱",
    companypostalcode: "公司郵遞區號",
    companyaddress: "公司地址",
    taxnumber: "稅籍編號",
    taxationbureau: "所屬國稅局",
    storepostalcode: "郵遞區號",
    storeaddress: "門市地址",
    principalname: "負責人姓名",
    principalphone: "負責人電話",
    principalfax: "負責人傳真",
    principalemail: "負責人Email",
    contactpersonphone: "聯絡人電話",
    contactpersondax: "聯絡人傳真",
    contactpersonemail: "聯絡人Email",
    invoicesheets: "每月預計開立發票",
    invoiceincreasesheets: "增加發票張數",
    maingoods: "主要販售商品",
    receiver: "收件人",
    receiveraddress: "收件地址",
    receiverphone: "收件人電話",
    applicationid: "ID",
    ishead: "總分支機構",
    isheadtext: "總分支機構",
    extraprice: "加價",
    isselect: "預選",
    add: "新增",
    remove: "移除",
    pricemarkup: "加價",
    alladd: "全選",
    min: "最少",
    max: "最多",
    name: "名稱",
    use: "使用",
    delete: "刪除",
    fullnumber: "滿",
    isrepeat: "重複折扣",
    modify: "修改",
    isvoid: "已作廢",
    changestatus: "變更狀態",
    status: "狀態",
    select: "選擇",
    selectall: "全選",
    item: "項目",
    isinvoicehassend: "發票寄送Mail",
    isinvoicehasprint: "發票列印",
    pgtalkprice: "PGTalk",
    pgtalkpt: "PGTalk PT",
    validmonths: "有效月份",
    keeptotal: "續會金額",
    keepmonths: "續會統計月份",
    visiontype: "用途",
    visiontypetext: "用途",
    builddate: "建立時間",
    batchnumber: "批號",
    phone1: "電話 1",
    phone2: "電話 2",
    changepricetype: "促銷別",
    changepricetypetext: "促銷別",
    salpricetype: "售價別",
    salpricetypetext: "售價別",
    hasbatchnumber: "啟用批號",
    purchaseorderqty: "採購數量",
    saleorderqty: "訂單數量",
    sequence: "排序",
    agencystartdate: "委任開始日期",
    availablyqty: "可用量",
    downloaditemname: "下載項目名稱",
    issuccess: "已下載",
    issuccesstext: "是否成功",
    onlineordermenu: "菜單",
    onlineordermenutext: "菜單",
    degreestartdate: "自動升等開始",
    degreeenddate: "自動升等結束",
    specificationtypename: "規格類型",
    specificationname: "規格名稱",
    terminalid: "收銀台ID",
    apitype: "API類型",
    apitypetext: "API類型",
    requesturl: "請求網址",
    requestmethod: "請求方法",
    responsecode: "狀態碼",
    issucccess: "是否成功",
    durationtime: "持續時間",
    requesttime: "請求時間",
    thirdpartytype: "第三方類型",
    thirdpartytypetext: "第三方類型",
    httpmethod: "請求方法",
    durationseconds: "持續秒數",
    userid: "使用者ID",
    systemlogtype: "系統紀錄類型",
    systemlogtypetext: "系統紀錄類型",
    description: "敘述",
    requestcontent: "Request Content",
    responsecontent: "Response Content",
    requestbody: "Request Body",
    notificationtype: "推播類型",
    notificationtypetext: "推播類型",
    orderid: "訂單ID",
    lastnotificationtime: "最後推播時間",
    resendtimes: "傳送次數",
    webhooklogid: "回呼紀錄ID",
    webhooktype: "回呼類型",
    webhooktypetext: "回呼類型",
    clientip: "客戶端IP",
    transactionid: "交易ID",
    errormsg: "錯誤訊息",
    signature: "簽名",
    id: "ID",
    apptype: "App Type",
    invoicetime: "發票時間",
    floormapid: "樓層ID",
    tablemapid: "桌位ID",
    tablemapname: "桌位名稱",
    partid: "桌位ID",
    index: "項次",
    body: "Body",
    thirdpartybody: "Third Party Body",
    authcode: "授權碼",
    cardnumber: "卡號",
    creditcardtradenumber: "交易序號",
    brandname: "品牌名稱",
    brandnumber: "品牌編號",
    broadcastid: "Broadcast Id",
    version: "版本",
    ubereatsprice: "UberEats",
    purchasememo: "單據備註",
    tablemapname: "桌位名稱",
  },
  //columnName

  //dataBodyPart
  dataBodyPart: {
    search: "搜尋",
    directorykind: "科目類別",
    qty: "數量",
  },
  //dataBodyPart

  //dataModalPart
  dataModalPart: {
    none: "無",
    number: "編號",
    name: "名稱",
    food: "商品",
    foodkindid: "類別",
    foodnumber: "編號",
    foodname: "名稱",
    foodname2: "名稱2",
    barcode: "條碼",
    barcode2: "條碼2",
    barcode3: "條碼3",
    isbarcodeexist: "此條碼已存在",
    unit: "單位",
    salprice1: "售價1",
    salprice2: "售價2",
    salprice3: "售價3",
    salprice4: "售價4",
    salprice5: "售價5",
    salprice6: "售價6",
    cost: "成本",
    taxtype: "稅別",
    integralget: "獲得積分",
    integralfree: "兌換積分",
    memo: "備註",
    memo2: "備註2",
    memo3: "備註3",
    isscale: "需過磅",
    nodiscount: "不可折扣",
    isstopsale: "停售",
    noservice: "免服務費",
    iscustomerstock: "會員庫存",
    nogiveintegral: "不納入總金額獲得積分",
    color: "顏色",
    foodsetting: "食品",
    othersetting: "其他",
    erpsetting: "進銷存",
    membersetting: "會員",
    fontsize: "字體大小",
    forecolor: "文字顏色",
    backcolor: "背景顏色",
    ingredient: "成分",
    storage: "保存方法",
    origin: "產地",
    supplierid: "廠商",
    purchaseqty: "採購基數",
    purchasefoodid: "採購商品 (不輸入則代原商品)",
    foodstocktype: "存貨別",
    isstock: "扣庫存",
    issaleautocombination: "自動產生組合單",
    store: "門市",
    birth: "生日",
    cellphone: "手機",
    phone1: "電話1",
    phone2: "電話2",
    email: "電子信箱",
    identifycard: "身分證",
    gender: "性別",
    address: "地址",
    uniformnumber: "統一編號",
    vehiclenumber: "載具",
    customerdegree: "會員等級",
    contactperson: "聯絡人",
    salesperson: "業務員",
    noocard: "非Ocard會員",
    pickupaddress: "取貨方式",
    faxnumber: "傳真號碼",
    hasbatchnumber: "啟用批號",
    employeetype: "職稱",
    isMultiSpecification: "啟用多規格",
    countrycode: "國碼",
    multispecification: "多規格",
    isspecificationsamepromotion: "子商品促銷同母商品",
    isspecificationsametaste: "子商品口味同母商品",
    foodkindfontsize: "類別字體大小",
    foodkindforecolor: "類別文字顏色",
    foodkindbackcolor: "類別背景顏色",
    foodfontsize: "商品字體大小",
    foodforecolor: "商品文字顏色",
    foodbackcolor: "商品背景顏色",
    language: "語言",
    multilanguage: "多語言",
    chinese: "中文 (ZH)",
    english: "英文 (EN)",
    japanese: "日文 (JP)",
    korean: "韓文 (KR)",
    french: "法文 (FR)",
  },
  //dataModalPart

  //searchAndTablePart
  searchAndTablePart: {
    number: "編號",
    name: "名稱",

    foodkindnumber: "類別編號",
    foodkindname: "類別名稱",
    foodnumber: "編號",
    foodname: "名稱",
    barcode: "條碼",
    taxtype: "稅別",
    customernumber: "會員編號",
    customername: "會員名稱",
    customerdegree: "會員分級",
    cellphone: "手機",
    createtime: "建立時間",
    updatetime: "最後修改時間",
    suppliernumber: "編號",
    suppliername: "名稱",
    apitype: "API類型",
    thirdpartytype: "第三方類型",
    notificationtype: "推播類型",
    webhooktype: "回呼類型",
  },
  //searchAndTablePart

  //title
  title: {
    combokind: "套餐類別",
    combo: "套餐",
    directorykind: "科目類別",
    directory: "科目",
    tastekind: "口味類別",
    taste: "口味",
    store: "門市",
    unselectedfood: "未選商品",
    selectedfood: "已選商品",
    unselectedkind: "未選類別",
    selectedkind: "已選類別",
    foodimage: "商品圖片",
    memberwebimage: "會員自填表單封面",
    authority: "權限",
    unselectedterminal: "未選收銀台",
    selectedterminal: "已選收銀台",
    unselectedemployee: "未選員工",
    selectedemployee: "已選員工",
    promotion: "群組",
    promotiondets: "小群組",
    unselectedstore: "未選門市",
    selectedstore: "已選門市",
    customerlevels: "會員分級",
    invoice: "發票",
    sendinvoice: "開立發票",
    columnsetting: "欄位調整",
    selectcustomer: "選擇會員",
    selectdirectory: "選擇科目",
    selectmodule: "選擇模組",
    selectemployee: "選擇員工",
    selectfood: "選擇商品",
    selectfoodkind: "選擇商品類別",
    selectfoodmajorkind: "選擇商品大類別",
    selecttaste: "選擇口味",
    selecttastekind: "選擇口味類別",
    selectstore: "選擇門市",
    selectsupplier: "選擇廠商",
    selectterminal: "選擇收銀台",
    selectwarehouse: "選擇倉庫",
    invoiceimage: "發票抬頭圖片",
    selectinventory: "選擇盤點單",
    fullnumber: "滿",
    salpricetype: "售價別",
    selectmenu: "選擇菜單",
    food: "商品",
    specification: "規格",
    selectedbrand: "已選品牌",
    unselectedbrand: "未選品牌",
    cangain: "可獲得",
    cantgain: "無法獲得",
    tastekindsequence: "口味類別排序",
    tastesequence: "口味排序",
  },
  //title

  //button
  button: {
    food: "商品",
    save: "儲存",
    cancel: "取消",
    selectfood: "選擇商品",
    clear: "清除",
    food: "商品",
    foodkind: "商品類別",
    setting: "設定",
    select: "選擇",
    add: "新增",
    edit: "編輯",
    remove: "刪除",
    store: "門市",
    copy: "複製",
    supplier: "廠商",
    warehouse: "倉庫",
    inventory: "盤點",
    inventorylist: "盤點單",
    topurchase: "轉進貨",
    getoutofsafeqty: "載入不足安全存量",
    send: "開立",
    print: "列印",
    detail: "明細",
    sendemail: "寄信",
    void: "作廢",
    start: "開始",
    check: "點收",
    done: "完成",
    ok: "確定",
    backtopreviouspage: "回上一頁",
    import: "匯入",
    export: "匯出",
    //modules
    frontendfunctions: "前台功能",
    basic: "基本資料",
    modules: "模組設定",
    reports: "報表",
    customers: "會員",
    erp: "進銷存",
    accounts: "帳款",
    download: "下傳",
    upload: "上傳",
    //reportTypes
    simple: "簡要",
    detail: "明細",
    summary: "彙總",
    all: "全部",
    sale: "消費",
    store: "儲值",
    statistic: "統計",
    daystatistic: "日統計",
    warehouse: "倉庫",
    food: "商品",
    order: "訂單",
    available: "可用量",
    standard: "一般",
    import: "匯入",
    cloud: "雲端",
    auto: "自動",
  },
  //button

  //option
  option: {
    Default: "預設",
    None: "無",
    G: "克",
    TG: "臺斤",
    KG: "公斤",
    HG: "百克",
    All: "全部",
    YTax: "含稅",
    ZTax: "零稅",
    NTax: "免稅",

    //customerSearchType
    customerSearchType: {
      CustomerNumber: "會員編號",
      CustomerName: "會員名稱",
      Cellhpone: "手機",
    },

    //foodSearchType
    foodSearchType: {
      FoodKindName: "類別名稱",
      FoodKindNumber: "類別編號",
      FoodName: "商品名稱",
      FoodNumber: "商品編號",
      Barcode: "條碼",
      SupplierNumber: "廠商編號",
      SupplierName: "廠商名稱",
    },

    //supplierSearchType
    supplierSearchType: {
      SupplierNumber: "廠商編號",
      SupplierName: "廠商名稱",
      BusinessNumber: "統一編號",
      Telephone: "電話",
    },

    //businessClassType
    businessClassType: {
      All: "全部",
      Class1: "1",
      Class2: "2",
      Class3: "3",
      Class4: "4",
      Class5: "5",
      Class6: "6",
      Class7: "7",
      Class8: "8",
      Class9: "9",
      Class10: "10",
    },

    //costType
    costType: {
      NormalCost: "標準成本",
      AvgCost: "平均成本",
      LastCost: "前次進貨價",
    },

    employeeType: {
      General: "一般",
      Saler: "業務員",
    },

    //customerLevelType
    customerLevelType: {
      DegreeNone: "零級",
      DegreeOne: "一級",
      DegreeSecond: "二級",
      DegreeThird: "三級",
      DegreeFour: "四級",
      DegreeFive: "五級",
      DegreeSix: "六級",
    },

    //customerSaleType
    customerSaleType: {
      None: "無",
      SalPrice: "售價",
      FreePrice: "折價",
      Discount: "折扣",
    },

    //degreeType
    degreeType: {
      None: "無",
      Level0: "零級",
      Level1: "一級",
      Level2: "二級",
      Level3: "三級",
      Level4: "四級",
      Level5: "五級",
      Level6: "六級",
    },

    //directoryType
    directoryType: {
      None: "一般",
      Collection: "代收",
      Payment: "代支",
      Sale: "單據",
      Food: "商品",
      DeleteFood: "刪菜",
      VoidSale: "作廢",
      VoidDeposit: "作廢儲值單",
      RejectOnlineOrder: "拒接網路訂單",
      StockAdjust: "調整單",
    },

    //eatSearchType
    eatSearchType: {
      All: "全部",
      In: "內用",
      Out: "外帶",
      Delivery: "外送",
      InAndOut: "內用&外帶",
      InAndDelivery: "內用&外送",
      OutAndDelivery: "外帶&外送",
      PickSelf: "自取",
      InAndPickSelf: "內用&自取",
      OutAndPickSelf: "外帶&自取",
      DeliveryAndPickSelf: "外送&自取",
      InAndOutAndDelivery: "內用&外帶&外送",
      InAndOutAndPickSelf: "內用&外帶&自取",
      InAndDeliveryAndPickSelf: "內用&外送&自取",
      OutAndDeliveryAndPickSelf: "外帶&外送&自取",
    },

    //foodStockType
    foodStockType: {
      Product: "商品",
      Combination: "組合品",
    },

    //invoiceTargetType
    invoiceTargetType: {
      B2C: "個人",
      B2B: "公司",
    },

    //vehicleType
    vehicleType: {
      none: "無",
      cellphone: "手機載具",
      nature: "自然人憑證",
      donate: "捐贈",
      member: "會員載具",
    },

    //moduleType
    moduleType: {
      Authority: "權限",
      DayTime: "時間",
      Invisible: "商品隱藏",
      Function: "功能鍵",
      FoodSequence: "商品順序",
      Promotion: "促銷",
    },

    //ocardPromotionType
    ocardPromotionType: {
      Percent: "折扣",
      Price: "折價",
      Redeem: "兌換商品",
    },

    //waitingType
    waitingType: {
      Minute: "分鐘",
      Hour: "小時",
      Day: "天",
    },

    //waitingIntervalType
    waitingIntervalType: {
      Waiting5Mins: "5分鐘",
      Waiting10Mins: "10分鐘",
      Waiting15Mins: "15分鐘",
      Waiting20Mins: "20分鐘",
      Waiting30Mins: "30分鐘",
      Waiting60Mins: "60分鐘",
      Customize: "自訂",
    },

    //reserveDaysType
    reserveDaysType: {
      All: "無限制",
      Today: "當日",
      Customize: "自訂日期",
    },

    //overPayType
    overPayType: {
      None: "無",
      OverCharge: "溢收",
      CashChange: "現金找零",
      Change: "找零",
    },

    //payType
    payType: {
      Cash: "現金",
      CreditCard: "信用卡",
      EasyCard: "悠遊卡",
      Coupon: "折價券",
      PayType1: "結帳別1",
      PayType2: "結帳別2",
      Receivable: "應收帳款",
      Deposit: "儲值金",
      LinePay: "Line Pay",
      CouponForStock: "折價券(條碼)",
      iPASS: "一卡通",
      PXPay: "全支付",
      Scan2Pay: "Scan2Pay",
      PGTalk: "PGTalk",
      JKOPay: "街口支付",
    },

    //salPriceType
    SalPrice1: "售價1",
    SalPrice2: "售價2",
    SalPrice3: "售價3",
    SalPrice4: "售價4",
    SalPrice5: "售價5",
    SalPrice6: "售價6",

    //promotionType
    TotalPriceForFullQty: "滿數量改變總價",
    FreeQtyForFullQty: "滿數量贈送",
    TotalPiceForMatch: "紅配綠",
    DiscountForFullQty: "滿數量打折",
    SalPriceForFullQty: "滿數量改變單價",
    DiscountSalPrice: "促銷單價",
    FreePriceForFullPrice: "滿額折價",
    DiscountForFullPrice: "滿額打折",
    DiscountForTheQty: "第N件打折",
    FreePriceForFullQty: "滿數量折價",
    DiscountForGreaterThanQty: "N件以上打折",
    SameFood: "(同商品)",

    //ocardPromotionType
    Percent: "折扣",
    Price: "折價",
    Redeem: "兌換商品",

    //saleType
    SalPrice: "售價",
    FreePrice: "折價",
    Discount: "折扣",

    //taxType
    taxType: {
      NoInvoice: "不開發票",
      YTax: "含稅",
      ZTax: "零稅",
      NTax: "免稅",
    },

    //tradeStatusType
    Sale: "開立",
    Void: "作廢",

    //purchaseOrderStatusType
    NotFinishedYet: "未結案",
    Finished: "已結案",

    //timeType
    Hour: "小時",
    Day: "日",
    Month: "月",
    Season: "季",
    Year: "年",

    //rankType
    rankType: {
      None: "無",
      Qty: "數量",
      Total: "總價",
    },

    //yes or no
    yesOrNo: {
      yes: "是",
      no: "否",
    },

    //reportSourceType
    reportSourceType: {
      All: "全部",
      Local: "POS",
      Web: "網站",
      Ubereats: "UberEats",
      Backstage: "後台",
    },

    //lineOrderImageColorType
    lineOrderImageColorType: {
      Green: "綠色",
      Blue: "藍色",
      Yellow: "黃色",
      Orange: "橘色",
    },

    //foodStatusType
    foodStatusType: {
      All: "全部",
      Normal: "正常",
      Discontinued: "停售",
    },

    //visionType
    visionType: {
      Myopia: "近視",
      Hyperopia: "遠視",
      Contacts: "隱形眼鏡",
      Other: "其他",
    },

    //dateSourceType
    dateSourceType: {
      Delivery: "取貨日期",
      Account: "結帳日期",
      Trade: "日期",
    },

    //changePriceType
    changePriceType: {
      Total: "滿額變售價別",
      Qty: "滿數量變售價別",
      Function: "（搭配功能鍵）",
    },

    //qtyType
    qtyType: {
      All: "全部",
      GreaterThanZero: "大於0",
      LessThanZero: "小於0",
      Zero: "0",
      GreaterAndEqualZero: "大於等於0",
    },

    //elecInvoiceTitleType
    elecInvoiceTitleType: {
      Text: "文字",
      Picture: "圖片",
    },

    //apiType
    apiType: {
      Web: "Web",
      Broadcast: "Broadcast",
      Order: "Order",
      Member: "Member",
    },

    //thirdPartyType
    thirdPartyType: {
      None: "無",
      LinePay: "LinePay",
      PGTalk: "PGTalk",
      MyCard: "MyCard",
      PXPay: "PXPay",
      SurveyCake: "SurveyCake",
      Ocard: "Ocard",
      UberEats: "UberEats",
      Showmore: "Showmore",
      Mitake: "Mitake",
      Digiwin: "Digiwin",
      LinePayOnline: "LinePayOnline",
    },

    //systemLogType
    systemLogType: {
      None: "無",
      DeviceChangeCpu: "Device Change Cpu",
      UserChangePassword: "User Change Password",
      SystemChangeUserPassword: "System Change User Password",
      InitailizeSale: "Initailize Sale",
      InitailizeERP: "Initailize ERP",
      DeleteUberEats: "Delete UberEats",
    },

    //notificationType
    notificationType: {
      All: "全部",
      None: "無",
      OnlineOrder: "線上點餐",
      UberEatsOrder: "UberEats點餐",
      UberEatsOrderCancel: "UberEats取消點餐",
      TableOrder: "桌邊點餐",
    },

    //webhookType
    webhookType: {
      None: "無",
      Order: "線上點餐",
      UbereatsOrder: "Ubereats訂單",
      UbereatsOrderCancel: "Ubereats取消訂單",
      UbereatsStoreProvision: "Ubereats店家授權",
      UbereatsStoreDeProvision: "Ubereats店家取消授權",
      SendGrid: "Send Grid",
      Showmore: "Showmore",
    },

    //customerIdFillType
    customerIdFillType: {
      manually: "手動",
      autofordate: "依月分自動產生",
    },
  },
  //option

  //itemModal modalPart form
  modal: {
    all: "全部",
    default: "預設",
    none: "無",
    name: "名稱",
    number: "編號",
    number2: "單號",
    memo: "備註",
    kind: "種類",
    kind2: "類別",
    food: "商品",
    foodkind: "類別",
    foodmajorkind: "大類別",
    unitprice: "單價",
    store: "門市",
    modulestore: "門市模組",
    password: "密碼",
    birth: "生日",
    phone: "電話",
    cellphone: "手機",
    telephone: "電話",
    email: "電子信箱",
    identifycard: "身分證",
    gender: "性別",
    departmentid: "部門",
    defaultwarehouse: "預設倉庫",
    warehouse: "倉庫",
    address: "地址",
    cloudterminalid: "雲端收銀台",
    isnotsalesperson: "非業務員",
    foodnumber: "編號",
    foodname: "名稱",
    foodmajorkindid: "商品大類別",
    foodkindnumber: "類別編號",
    foodkindname: "類別名稱",
    fontsize: "字體大小",
    forecolor: "文字顏色",
    backcolor: "背景顏色",
    samecolor: "商品按鈕同類別",
    nosamecolor: "空按鈕同類別",
    isfoodsamefontsize: "商品文字大小同類別",
    value: "值",
    unit: "單位",
    functiontype: "功能別",
    paytype: "付款類別",
    overpaytype: "多收類型",
    price: "金額",
    pricemarkup: "加價",
    account: "帳戶",
    identifier: "識別碼",
    totallength: "總長度",
    pricelabeltype: "種類",
    foodposition: "商品位置",
    foodlength: "商品長度",
    priceposition: "價格位置",
    pricelength: "價格長度",
    pricedecimalnumber: "價格小數位",
    qty: "數量",
    qtyposition: "數量位置",
    qtylength: "數量長度",
    qtydecimalnumber: "數量小數位",
    singlediscount: "單項折扣",
    totaldiscount: "小計折扣",
    singleaddition: "單項加成",
    totaladdition: "小計加成",
    singleallowance: "單項折讓",
    totalallowance: "小計折讓",
    parttotaldiscount: "部分小計折扣",
    principal: "負責人",
    uniformnumber: "統一編號",
    isselect: "預選",
    elecinvoicetitle: "發票抬頭",
    companyname: "公司名稱",
    companyaddress: "公司地址",
    lycustomernumber: "預設會員",
    lywarehousenumber: "凌越倉庫編號",
    lyuniformnumber: "凌越申報統編",
    discountprice: "折抵",
    ntd: "元",
    min: "最少",
    max: "最多",
    displayname: "顯示名稱",
    promotionname: "群組名稱",
    promotiontype: "促銷種類",
    copyto: "複製到",
    daymodule: "時間模組",
    customizefood: "自訂商品",
    isrepeat: "重複折扣",
    total: "小計",
    total2: "總計",
    satisfy: "滿",
    amount: "個",
    amount2: "件",
    buy: "買",
    present: "送",
    freeprice: "折價",
    discount: "打折",
    sale: "售",
    from: "第",
    greaterthan: "大於",
    customerdegree: "會員等級",
    customergrading: "會員分級",
    point: "積點",
    p: "點",
    exchange: "每兌換",
    candiscount: "積點折",
    ntdtoget: "元獲得",
    nopromotioncombo: "不合併促銷優惠",
    discountkind: "優惠種類",
    memberspecificstore: "會員指定門市",
    degree: "等級",
    validmonths: "有效月份",
    accumulatedamounts: "累積金額",
    accumulatedmonths: "累積月份",
    keepamounts: "續會金額",
    keepmonths: "續會統計月份",
    table: "桌位",
    inventorydate: "盤點日期",
    inventorynumber: "盤點單號",
    writername: "填單人",
    warehouse: "倉庫",
    noinventory: "未盤到",
    import: "匯入",
    export: "匯出",
    date: "日期",
    purchasedate: "日期",
    purchasenumber: "進貨單號",
    purchasereturnnumber: "進退單號",
    accountdate: "帳款日期",
    supplier: "廠商",
    buyer: "採購人",
    contactperson: "聯絡人",
    invoicenumber: "發票號碼",
    invoicedate: "發票日期",
    taxtype: "稅別",
    istaxaddition: "預設外加稅",
    importsafeqty: "匯入安全存量",
    customer: "會員",
    invoicetargettype: "開立類型",
    vehicletype: "載具類別",
    vehiclenumber: "載具號碼",
    vehicletypenumber: "載具類別號碼",
    format1: "格式一",
    format2: "格式二(A4)",
    format3: "格式二(中一刀)",
    format4: "格式一(A4)",
    stockadjustnumber: "調整單號",
    stockadjusttype: "調整類別",
    directory: "科目",
    stocktransferdate: "調撥日期",
    stocktransfernumber: "調撥編號",
    fromwarehouse: "出倉",
    towarehouse: "入倉",
    checkername: "點收人",
    salesperson: "業務員",
    timetype: "時段",
    ranktype: "排行",
    eattype: "用餐",
    salepriceseperate: "單價不同",
    combine: "合併",
    seperate: "分開",
    businessclass: "班別",
    hide: "隱藏",
    show: "顯示",
    today: "今天",
    foodsupplier: "商品廠商",
    status: "狀態",
    employee: "員工",
    terminal: "收銀台",
    basic: "基本資料",
    downloaditem: "下傳項目",
    oopsmessage: "抱歉，您所訪問的網頁不存在或已刪除 !",
    foodrank: "商品排行",
    foodkindrank: "類別排行",
    storedata: "門市分析",
    timedata: "時段比較",
    people: "人數",
    salecounts: "次數",
    addcategory: "新建",
    categoryname: "類別名稱",
    additem: "新建",
    corresponditem: "對應商品",
    daymodule: "日期模組",
    waitingtype: "最快候餐時間單位",
    waitinginterval: "候餐時間區間",
    waitingintervalcustomize: "自訂候餐時間區間(分鐘)",
    waitingnum: "最快候餐時間",
    reservedays: "可預訂日期",
    reservedayscustomize: "自訂可預訂日期上限(天)",
    lastorderminutes: "最後點餐時間(分鐘)",
    specialist: "承辦人",
    combination: "主件品",
    paymentdate: "付款日期",
    paymentnumber: "付款單號",
    costtype: "成本",
    liffid: "LIFF ID",
    onlineorderliffid: "線上點餐 LIFF ID",
    memberliffid: "會員網站 LIFF ID",
    messagetoken: "Message Token",
    qrcodeurl: "QR Code 網址",
    channelid: "Channel ID",
    channelsecret: "Channel Secret",
    isshowall: "顯示全部",
    memberupgrade: "會員自動升等",
    memberupgradesetting: "會員自動升等設定",
    reportsourcetype: "來源",
    surveycakeurl: "SurveyCake 網址",
    foodstatus: "商品狀態",
    visiontype: "用途",
    builddate: "建立日期",
    builder: "建立者",
    prescription: "驗光師",
    batchnumber: "商品批號",
    accountdate: "結帳日",
    faxnumber: "傳真號碼",
    batchnumber: "批號",
    search: "搜尋",
    keyword: "關鍵字",
    invoicetitle: "客戶抬頭",
    integrationenabled: "整合開關",
    ubereatspromotionfood: "UberEats促銷商品",
    nogiveintegral: "不納入總金額獲得積分",
    elecinvoicetitletype: "發票抬頭顯示",
    nogiveintegral: "不贈送點數",
    nogiveintegralforcash: "現金",
    nogiveintegralforcreditcard: "信用卡",
    nogiveintegralforpaytype1: "付款別1",
    nogiveintegralforpaytype2: "付款別2",
    nogiveintegralforreceivable: "應收帳款",
    nogiveintegralfordiscount: "折扣",
    specificationtypename: "規格類型",
    specificationname: "規格名稱",
    onlineorderinsalpricetype: "內用售價別",
    onlineorderpickselfsalpricetype: "外帶售價別",
    period: "期別",
    indonetitle: "內用結帳抬頭",
    outdonetitle: "外帶結帳抬頭",
    qrcode: "QRcode",
    importpromotion: "匯入促銷模組",
    warehousetype: "倉庫別",
    isfulldownload: "完整下載",
    customeridfilltype: "會員編號規則",
    client: "客戶",
    importbrand: "匯入品牌",
    maxdiscount: "最大折扣",
    maxfree: "最大折讓",
    english: "英文",
    japanese: "日文",
    korean: "韓文",
    french: "法文",

    //modalTitle
    addaccount: "新增帳戶",
    editaccount: "編輯帳戶",
    adddirectorykind: "新增科目類別",
    editdirectorykind: "編輯科目類別",
    adddirectory: "新增科目",
    editdirectory: "編輯科目",
    addemployee: "新增員工",
    editemployee: "編輯員工",
    addcombokind: "新增套餐類別",
    editcombokind: "編輯套餐類別",
    adddepartment: "新增部門",
    editdepartment: "編輯部門",
    addfoodkind: "新增類別",
    editfoodkind: "編輯類別",
    addfoodmajorkind: "新增大類別",
    editfoodmajorkind: "編輯大類別",
    addfood: "新增商品",
    editfood: "編輯商品",
    addfunction: "新增功能",
    editfunctionbtn: "編輯功能鍵",
    addpay: "新增付款別",
    editpay: "編輯付款別",
    addpricelabel: "新增價格標",
    editpricelabel: "編輯價格標",
    addstore: "新增門市",
    editstore: "編輯門市",
    addsupplier: "新增廠商",
    editsupplier: "編輯廠商",
    addtastekind: "新增口味類別",
    edittastekind: "編輯口味類別",
    addtaste: "新增口味",
    edittaste: "編輯口味",
    elecinvoice: "電子發票",
    editterminal: "編輯收銀台",
    batchprice: "批次改價",
    addwarehouse: "新增倉庫",
    editwarehouse: "編輯倉庫",
    addmodule: "新增模組",
    editmodule: "編輯模組",
    addpromotion: "新增群組",
    editpromotion: "編輯群組",
    addpromotiondets: "新增小群組",
    editpromotiondets: "編輯小群組",
    addcustomer: "新增會員",
    editcustomer: "編輯會員",
    addintegralrule: "新增規則",
    editintegralrule: "編輯規則",
    addmemberupgraderule: "新增規則",
    editmemberupgraderule: "編輯規則",
    addocardrule: "新增規則",
    editocardrule: "編輯規則",
    addtable: "新增桌位",
    edittable: "編輯桌位",
    importinventory: "匯入盤點單",
    exportinventory: "匯出盤點單",
    importsale: "匯入銷貨單",
    importfood: "匯入商品",
    importfoodkind: "匯入商品類別",
    importfoodmajorkind: "匯入商品大類別",
    importsupplier: "匯入廠商",
    importcustomer: "匯入會員",
    adddownload: "新增下傳",
    addcategory: "新增類別",
    editcategory: "編輯類別",
    additem: "新增商品",
    edititem: "編輯商品",
    addmenu: "新增菜單",
    editmenu: "編輯菜單",
    select: "選擇",
    addchangepriceforprice: "新增滿額變售價別",
    editchangepriceforprice: "編輯滿額變售價別",
    addchangepriceforqty: "新增滿數量變售價別",
    editchangepriceforqty: "編輯滿數量變售價別",
    errormessage: "錯誤訊息",
    thirdparty: "第三方",
    system: "系統",
    notification: "推播",
    webhook: "回呼",
    eatsearchtype: "用餐別",
    addbrand: "新增品牌",
    editbrand: "編輯品牌",
  },
  //itemModal modalPart

  //placeholder
  placeholder: {
    dbselect: "請點擊兩下",
    dbselectsupplier: "請點擊兩下選擇廠商",
    dbselectcustomer: "請點擊兩下選擇客戶",
    dbselectfood: "請點擊兩下選擇商品",
    dbselectwarehouse: "請點擊兩下選擇倉庫",
    dbselectbuyer: "請點擊兩下選擇採購人",
    dbselectsalesperson: "請點擊兩下選擇業務員",
    dbselectdirectory: "請點擊兩下選擇科目",
    dbselectcombination: "請點擊兩下選擇主件品",
    inputfoodnumberorbarcode: "請輸入商品編號或條碼",
    inputfoodnumberordb: "請輸入商品編號或點擊兩下選擇",
    inputfoodnumber: "請輸入商品編號",
    inputcustomernumberordb: "請輸入會員編號或點擊兩下選擇",
    inputcustomernumber: "請輸入會員編號",
    inputsalernumberordb: "請輸入業務員編號或點擊兩下選擇",
    inputwarehousenumberordb: "請輸入倉庫編號或點擊兩下選擇",
    inputsuppliernumberordb: "請輸入廠商編號或點擊兩下選擇",
    inputbuyernumberordb: "請輸入採購人編號或點擊兩下選擇",
    chooseanimage: "上傳圖片",
  },
  //placeholder

  //func.message
  funcMsg: {
    importsuccess: "匯入成功",
    plsselectterminal: "請選擇收銀台",
    plsselectitem: "請選擇項目",
    plsselectdata: "請選擇一筆資料",
    plsselectsupplier: "請選擇廠商",
    plsselectwarehouse: "請選擇倉庫",
    plsselectcustomer: "請選擇會員",
    plsselectdiscountkind: "請選擇優惠種類",
    plsselectcombokind: "請選擇套餐類別",
    thispaymentdidnthavedet: "此筆單無應付單據",
    cantfindthisdata: "查無此筆單據",
    atleastsetoneprice: "請至少設定一筆沖帳金額",
    thisreceiptdidnthavedet: "此筆單無應收單據",
    thisrulecantsetfood: "此規則無法設定商品",
    areyousuretodeletethis: "確定刪除嗎",
    success: "成功",
    updatesuccess: "更新成功",
    removesuccess: "刪除成功",
    imageupdatesuccess: "圖片更新成功",
    imageremovesuccess: "圖片清除成功",
    imagesizecantover150kb: "圖片尺寸不可超過150KB",
  },
  //func.message
};

export default tw;
